<template>
<div>
    <AppNavbar />
         <router-view />
    <Footer />
</div>
</template>
<script>
import AppNavbar from '../farm-ECO/AppNavbar.vue'
import Footer from '../Footer.vue'

export default {
  name: 'HomeLayout',
  components: {
    AppNavbar,
      Footer
  }
}
</script>
<style lang="css" scoped>
    @import '../../assets/jackpot/css/las.css';
    @import '../../assets/css/bootstrap.min.css';
    @import '../../assets/css/style.css';
    @import '../../assets/css/farm.css';
    @import '../../assets/nft/css/style.css';
</style>